<template>
  <div class="page-wrapper">

    <!-- FR -->
    <article class="terms" v-if="$route.meta.lang === 'fr'">
      <div class="content">
        <h2 class="title">POLITIQUE DE VIE PRIVÉE</h2>
        <div class="regular-text">
          <p class="regular-text">
            GESTEV vous informe de l’usage que nous faisons des renseignements que nous recueillons lorsque vous visitez notre site Web (ou encore nos
            plateformes de médias sociaux ou nos applications spécialisées), lorsque vous vous inscrivez à nos événements ainsi que lorsque vous visitez
            les sites de nos compagnies affiliées faisant l’objet d’un hyperlien que nous autorisons (ci-après le « Site »). Par conséquent, la présente
            Politique de vie privée (ci-après la « Politique ») définit la façon dont nous recueillons, utilisons, divulguons et traitons les
            renseignements que vous nous communiquez.
          </p>
          <h3 class="medium-title">RENSEIGNEMENTS PERSONNELS</h3>
          <p class="regular-text">
            <strong>1. Que signifient les « Renseignements personnels » (ci-après « RP ») et comment sont-ils collectés ?</strong>
            Les RP sont des renseignements concernant une personne identifiable. C’est-à-dire, des informations qui peuvent être utilisées afin de vous
            identifier. Dans certaines circonstances bien particulières, soit lors de l'inscription à nos différents services de personnalisation, de
            courriels et de concours publicitaires en ligne, GESTEV demande que vous inscriviez vos nom, prénom, adresse, adresse de courriel et certains
            autres renseignements dans un formulaire à l'écran. Nos serveurs peuvent aussi recueillir l’adresse IP de votre ordinateur et le nom de votre
            fournisseur de service Internet. Dans la plupart des cas, ces renseignements ne sont pas des RP mais plutôt des Données de navigation, et nous
            vous renvoyons à la section « Données de navigation » ci-dessous pour mieux comprendre notre politique à ce sujet.
          </p>

          <p class="regular-text">
            <strong>2. Comment les RP sont-ils utilisés ?</strong>
            La collecte des RP nous permet de vous fournir nos services, de vous offrir un service plus pertinent à vos besoins, améliorer le contenu,
            l’interface et les caractéristiques du Site en fonction de l’information recueillie, de vous joindre dans l'éventualité où vous gagneriez un
            prix et, si vous nous en avez signifié le désir, de vous faire parvenir, ponctuellement, de l'information par courriel. Ces données sont
            utilisées également à des fins de contrôle et visent l’amélioration de l’utilisation.
          </p>

          <p class="regular-text">
            <strong>3. Les RP sont-ils partagés avec des tiers ?</strong>
            Nous ne transférons, ne vendons et ne divulguons pas vos RP de quelque manière que ce soit à des tiers, incluant nos annonceurs, autres clients
            ou partenaires, sans avoir préalablement obtenu votre consentement. Nonobstant ce qui précède, vos RP peuvent être communiqués : (i) aux
            filiales et compagnies liées à GESTEV, (ii) aux compagnies tierces engagées par GESTEV afin de stocker ou traiter ces renseignements au nom et
            pour le compte de GESTEV selon des ententes contractuelles garantissant la confidentialité et la sécurité de ces RP et iii) aux partenaires de
            GESTEV dans le cadre d’événements ou de projets spécifiques. Dans ce dernier cas, ce sont les partenaires de GESTEV qui sont responsables de la
            confidentialité de vos RP en vertu de leurs propres politiques et GESTEV se dégage de toute responsabilité à cet égard. Veuillez noter que
            GESTEV peut utiliser les services de fournisseurs situés à l’extérieur du Canada. De ce fait, il est possible, dans de rares cas, que les lois
            en vigueur dans ces pays étrangers permettent la communication des RP à des autorités judiciaires, quasi-judiciaires ou gouvernementales.
          </p>

          <p class="regular-text">
            <strong>4. Recevons-nous des RP de tiers?</strong>
            Lorsque vous utilisez l’authentification unique de nos partenaires de réseaux sociaux (soit Facebook, Twitter, Yahoo! et Google), nous
            pourrions recevoir votre adresse courriel, de même que les informations de votre profil public. Si vous souhaitez contrôler ces informations,
            vous devez configurer vos paramètres de vie privée sur les réseaux sociaux concernés.
          </p>

          <p class="regular-text">
            <strong>5. Comment et pendant combien de temps les RP sont-ils conservés ?</strong>
            Les RP recueillis sont sauvegardés dans des serveurs inaccessibles au public et sont protégés au moyen de mesures de sécurité appropriées.
            Ainsi, soyez assurés que des mesures de sécurité sont mises en place pour protéger vos données contre la perte, le vol, la consultation, la
            copie, l’utilisation ou les modifications non autorisées. Nous ne conserverons les RP que dans la mesure où elles demeurent nécessaires ou
            pertinentes aux fins mentionnées dans la Politique ou conformément aux exigences de la loi.
          </p>

          <p class="regular-text">
            <strong>6. Quels sont les risques?</strong>
            Nonobstant ce qui précède, et malgré notre bonne volonté et nos mesures mises en place, aucune transmission de données sur le Site ni sur
            Internet en général n’est totalement garantie ou sans risque. Nous ne pouvons ainsi garantir la sécurité absolue de vos RP et nous ne pouvons
            donc accepter toute responsabilité concernant les tiers qui pourraient en faire usage ou mauvais usage, une interception en cours de
            transmission par le biais de l’Internet ou faisant l’objet de piratage informatique ou de fraude. Soyez vigilants, notre Site peut contenir des
            liens vers des sites Web gérés par des tiers ; ces liens ne vous sont proposés que pour votre agrément. L'activation de ces liens vous fait
            quitter le Site. Sachez que le Site et GESTEV n'exercent aucun contrôle sur les sites Web des tiers et que le fait que ces liens soient
            répertoriés sur le Site n'engage en aucun cas la responsabilité de GESTEV. De plus, sachez que certains tiers peuvent recueillir vos données à
            notre insu, telle que votre adresse IP et vos données de navigation. Nous mettons tout en œuvre pour que cela ne se produise pas, mais
            certaines pratiques de tiers demeurent hors de notre contrôle. Par ailleurs, la diffusion volontaire de RP lors de sessions de chat par
            messagerie, sur un blogue ou sur toute page interactive où vous contribuez publiquement par la mise en ligne de contenu que vous générez n'est
            pas couverte par la présente Politique. La vigilance est de mise lorsque vous communiquez vos RP par ces moyens et nous vous prions de prendre
            connaissance de nos Conditions d’utilisation. GESTEV se dégage de toute responsabilité à cet égard. Nous vous conseillons, en tout temps, de
            demeurer prudent et attentif lorsque vous êtes en ligne, naviguez sur Internet et lorsque vous créez du contenu sur Internet par l’ajout,
            notamment, de commentaires.
          </p>

          <p class="regular-text">
            <strong>7. Pouvez-vous accéder à vos RP?</strong>
            Oui, vous pouvez accéder aux RP vous concernant ou en demander la rectification en présentant une demande écrite à
            infovieprivee@quebecormedia.com. Nous serons en mesure de répondre à votre demande que dans le cas où nous avons un dossier personnalisé vous
            concernant (ce qui est le cas lorsque vous nous avez communiqué, ou que vous consentez à nous communiquer, des RP).
          </p>

          <h3 class="medium-title">DONNÉES DE NAVIGATION</h3>
          <p class="regular-text">
            <strong>1. Que signifient les « Données de navigation », et comment sont-elles collectées ?</strong>
            Les Données de navigation » sont des données anonymes concernant votre activité de navigation ou votre appareil. Par exemple, des données
            concernant votre adresse IP, votre historique de navigation, le contenu de ces pages, le nombre de clics que vous effectuez, votre type
            d’appareil, le site dont vous provenez et le site vers lequel vous allez en quittant le nôtre, votre lieu géographique imprécis, votre courriel
            haché, ainsi que les publicités et vidéos consultées sur le site. Les Données de navigation sont collectées en utilisant des fichiers témoins
            (« cookies »), soit des petits fichiers de données qui s’inscrivent sur le disque dur de vos appareils électroniques lorsque vous naviguez sur
            ce site. Ces fichiers témoins sont automatiquement transmis par le serveur de ce site vers le navigateur de votre appareil et sont archivés,
            par défaut, sur le disque dur de ce dernier. Les Données de navigation sont anonymes, de sorte que nous ne les utilisons pas dans le but de
            découvrir votre identité (par couplage ou autrement). Elles ne sont utilisées que pour les fins décrites ci-après.
          </p>

          <p class="regular-text">
            <strong>2. Qui collecte les Données de navigation ?</strong>
            Les Données de navigation sont ou peuvent être collectées, en tout ou en partie, par (i) nous, soit GESTEV, ses filiales et sociétés liées
            (ci-après « nous ») et (ii) certaines sociétés avec lesquelles nous collaborons à des fins commerciales ou non commerciales (telles que nos
            partenaires publicitaires, partenaires de contenus, sociétés qui pourraient acquérir certains de nos actifs, fournisseurs de services, de
            solutions ou de plateformes technologiques reliés à ce site) (« Partenaires »). Les données que nous pouvons recueillir ainsi que nos
            Partenaires sont dites « non sensibles » et sont sous une forme dépersonnalisée. De plus, nos Partenaires sont tenus par la loi d’adhérer aux
            principes reconnus de protection des renseignements personnels et respecter leurs propres politiques en matière de protection de vie privée.
          </p>

          <p class="regular-text">
            <strong>3. Comment les Données de navigation sont-elles utilisées ?</strong>

            Ces renseignements sont collectés afin de nous permettre : (a) de générer des données statistiques concernant notre niveau de lectorat, la
            performance et l’utilisation de nos sites et de leurs contenus et le genre de personne pour qui ils suscitent de l’intérêt; (b) d’améliorer
            votre expérience de navigation. Par exemple, les données sur votre navigateur [à savoir les fonctions de langue, le fuseau horaire et le module
            d’extensions] vous seront proposées par défaut lors de vos visites subséquentes sur ce site afin que vous n’ayez pas à saisir, de façon
            répétitive, certains paramètres qui vous sont propres ; et (c) de vous fournir des publicités en lien avec vos intérêts, comme par exemple des
            publicités en lien avec les sites et contenus que vous avez consultés.
          </p>

          <p class="regular-text">
            <strong>4. Comment pouvez-vous éviter la collecte de vos Données de navigation ?</strong>
            Vous pouvez en tout temps, à partir de votre navigateur, simplement effacer les témoins présents sur vos ordinateurs ou désactiver entièrement
            la fonctionnalité qui conserve ou archive des témoins sur ce site. Certaines fonctionnalités existent également afin de vous permettre de
            désactiver certains témoins et conserver uniquement les témoins que vous désirez. Si cela vous intéresse, référez-vous aux paramètres de votre
            navigateur. Si vous choisissez de désactiver ces témoins, vous pourrez tout de même accéder à l'information se trouvant sur ce site. Cette
            désactivation fera toutefois en sorte que ce site vous traitera comme un nouvel usager à chacune de vos visites et que seules des publicités
            aléatoires et non personnalisées vous seront diffusées. Il se peut toutefois que certaines fonctionnalités avancées de ce site soient
            involontairement affectées, certains témoins assurant les interrelations entre les diverses pages de celui-ci. Si cette désactivation affecte
            votre navigation, nous vous invitons à communiquer, en tout temps, avec nous par l’entremise de nos coordonnées disponibles sur ce site.
          </p>

          <span class="date">21 avril 2021</span>
        </div>
      </div>
    </article>

    <!-- EN -->
    <article class="terms" v-if="$route.meta.lang === 'en'">
      <div class="content">
        <h2 class="title">PRIVACY POLICY
</h2>
        <div class="regular-text">
          <p class="regular-text">
            GESTEV hereby informs you about the use we make of the information we collect when you visit our Website (or our social media platforms or specialized applications), when you register for our events and when you visit the sites of our affiliated companies via a hyperlink which we authorize (hereafter, the “Site”). Accordingly, this Privacy Policy (hereafter, the “Policy”) defines how we collect, use, disclose and process the information you provide.
          </p>
          <h3 class="medium-title">PERSONAL INFORMATION
</h3>
          <p class="regular-text">
            <strong>1. What does “Personal Information” (hereafter “PI”) mean and how is it collected?
</strong>
            PI is information about an identifiable individual. In other words, it is information which can be used to identify you. In some very specific circumstances, such as when you register for our various personalization services, email services and online contests, GESTEV asks that you enter your name, first name, address, email address, and certain other information in a form on the screen.

Our servers may also collect your computer’s IP address and the name of your Internet service provider. In most cases, that information is not PI, but rather Browsing Data, and we refer you to the “Browsing Data” section below to better understand our policy in that regard.


          </p>

          <p class="regular-text">
            <strong>2. How is the PI used?
</strong>
            Collecting PI allows us to be able to provide you with our services, to provide you with a service that is more relevant to your needs, to improve the content, the interface and the features of the Site based on the collected information, to contact you in the event that you win a prize and, if you informed us that you so desired, to send you information occasionally via email. Such data is also used for monitoring purposes and to improve utilization.


          </p>

          <p class="regular-text">
            <strong>3. Is the PI shared with third parties?
</strong>
            We do not transfer, sell or disclose your PI in any manner whatsoever to any third party, including our advertisers, other customers or partners, without first obtaining your consent. Notwithstanding the foregoing, your PI may be disclosed: (i) to GESTEV’s subsidiaries and affiliated companies and (ii) to third-party companies hired by GESTEV to store or process such information in the name and on behalf of GESTEV pursuant to contractual agreements which guarantee the confidentiality and safety of the PI, and iii) to GESTEV’s partners for specific events or projects. In the latter case, it is GESTEV’s partners who are responsible for the confidentiality of your PI in accordance with their own policies and GESTEV is relieved of any liability related to the processing of personal data in this context.

Please note that GESTEV may use the services of providers located outside of Canada. As a result, it is possible, in rare cases, that the applicable laws in those foreign countries allow PI to the disclosed to judicial, quasi-judicial or governmental authorities.


          </p>

          <p class="regular-text">
            <strong>4. Do we receive PI from third parties?
</strong>
            When you use our social media partners’ single sign-on (on Facebook, Twitter, Yahoo! and Google), we may receive your email address, as well as your public profile information. If you wish to control that information, you must configure your privacy settings on the relevant social networks.


          </p>

          <p class="regular-text">
            <strong>How is the PI stored and for how long?</strong>
            The PI collected is stored on servers that cannot be accessed by the public and is protected with appropriate security measures. Thus, rest assured that security measures are in place to protect your data against loss, theft, and unauthorized access, copying, use or modifications.

We keep the PI only to the extent it remains necessary or relevant for the purposes mentioned in the Policy or as required by law.
          </p>

          <p class="regular-text">
            <strong>6. What are the risks?</strong>
            Notwithstanding the above, and despite our good will and the measures we put in place, no data transmission on the Site or on Internet generally is completely guaranteed or without risk. Thus, we cannot guarantee the absolute security of your PI and therefore we cannot accept any liability for any third party who could use or misuse it, intercept it during transmission via Internet or as a result of hacking or fraud.

Be vigilant, our Site may contain links to websites managed by third parties, which are offered only for your enjoyment. You will leave the Site if you activate these links. Be aware that the Site and GESTEV have no control over third party websites and that GESTEV cannot, in any case, be held liable for such links being listed on the Site. You should also be aware that certain third parties may collect your data, such as your IP address and browsing data, without us knowing it. We do everything we can to prevent this from happening, but certain third-party practices remain beyond our control.

Furthermore, the voluntary disclosure of PI via messaging during chat sessions, on a blog or on any interactive page where you contribute publicly by posting online content that you generate is not covered by this Policy. It is important to remain vigilant when you communicate your PI through those means, and we ask that you read our Terms of use. GESTEV assumes no responsibility with regards thereto. We advise you, at all times, to be careful and to pay attention when you’re online, browse the Internet or create content on the Internet, in particular, by adding comments.
          </p>

          <p class="regular-text">
            <strong>7. Can you access your PI?
</strong>
            Yes, you can access the PI that concerns you or ask that it be corrected by submitting a request in writing to infovieprivee@quebecormedia.com. We will be able to answer your request only if we have a personalized file about you (which is the case when you have disclosed PI to us or consent to disclose PI to us).


          </p>

          <h3 class="medium-title">BROWSING DATA
</h3>
          <p class="regular-text">
            <strong>1. What does “Browsing Data” mean and how is it collected?
</strong>
            Browsing Data is anonymous data about your browsing activity or your device such as, for example, data concerning your IP address, your browsing history, the contents of such pages, the number of clicks you made, your type of device, the site you came from and the site you’re going to when you leave ours, your general geographic location, your shaded email, and the advertisements and videos viewed on the site.

The Browsing Data is collected by using cookies, which are small data files that are stored on the hard drive of your electronic device when you browse this site. The cookies are automatically sent by this site’s server to your device’s browser and are archived, by default, on its hard drive.

The Browsing Data is anonymous and is not used by us to discover your identity (by matching or otherwise). It is only used for the purposes described below.


          </p>

          <p class="regular-text">
            <strong>2. Who collects the Browsing Data?
</strong>
            The Browsing Data is or may be collected, in whole or in part, by (i) us, namely GESTEV, its subsidiaries and affiliated companies (hereafter, “us”) and (ii) certain companies with whom we collaborate for commercial or non-commercial purposes (such as our advertising partners, content partners, companies that may acquire some of our assets, or technological service, solution and platform providers connected to this site (“Partners”).

The data we and our Partners may collect is “non-sensitive” and depersonalized. Our Partners are also required by law to adhere to the recognized principles that apply to the protection of personal information and to comply with their own privacy policies.


          </p>

          <p class="regular-text">
            <strong>3. How is the Browsing Data used?
</strong>

            That information is collected to allow us :

(a) to generate statistical data regarding our level of readership, the performance and use of our sites and their contents and the type of person for whom they stir interest;

(b) to improve your browsing experience. For example, the data on your browser (namely, the language, time zone and plug-in features) will be offered to you by default during your subsequent visits on this site so that you do not need to readjust your specific settings; and

(c) to provide you with advertisements related to your interests such as, for example, advertisements related to the sites and contents you viewed.


          </p>

          <p class="regular-text">
            <strong>4. How can you prevent your Browsing Data from being collected?
</strong>
            You may, at any time, from your browser, simply delete the cookies stored on your computer or deactivate completely the feature which stores or archives cookies on that site. Some features also exist to allow you to deactivate certain cookies and to keep only the cookies you want. If you this is what you prefer, refer to the settings on your browser.

If you choose to deactivate the cookies, you will still be able to access the information on this site. However, if the cookies are deactivated, this site will treat you as a new user every time you visit, and only random non-personalized advertisements will be shared with you.

It is possible however that certain advanced features of this site may be unintentionally affected, as some cookies provide the interconnections between the various pages of the site. If such deactivation affects your browsing, please contact us, at any time, via the contact information listed on this site.


          </p>

          <span class="date">April 21, 2021</span>
        </div>
      </div>
    </article>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer'

export default {
  head() {
    return {
      title: {
        inner: 'Politique de vie privée',
      },
      meta: [
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {}
  },

  mounted() {},

  computed: mapState('app', ['appTitle']),

  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
