<template>
  <div class="page-wrapper">

    <!-- FR -->
    <article class="terms" v-if="$route.meta.lang === 'fr'">
      <div class="content">
        <h2 class="title">Plan du site</h2>
        <a
            href="/static/site-map.pdf"
            target="_blank"
            class="text"
            ><img class="full-img" src="/static/site-map.jpg" alt="Plan du site Bleu spectacle multimédia"></a>
        <h2 class="title">Questions & Réponses : Général</h2>
        <div class="regular-text">
          <h3 class="regular-title">Qu’est-ce que BLEU : Le Québec sous les étoiles&nbsp;?</h3>
          <p class="regular-text">
            BLEU : Le Québec sous les étoiles est un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif présenté à l’occasion de la
            fête nationale.
          </p>

          <h3 class="regular-title">À quel moment aura lieu BLEU : Le Québec sous les étoiles&nbsp;?</h3>
          <p class="regular-text">Le spectacle sera présenté du 18 juin au 24 juin (excluant le 23 juin).</p>

          <h3 class="regular-title">À quelle heure débute le spectacle?</h3>
          <p class="regular-text">Le spectacle débutera à 21 h 30 pour chacune des représentations</p>

          <h3 class="regular-title">Combien de temps dure le spectacle&nbsp;?</h3>
          <p class="regular-text">Le spectacle est d’une durée d’environ 20 minutes.</p>

          <h3 class="regular-title">Pourquoi ne faire qu’une représentation par soir?</h3>
          <p class="regular-text">
            Comme il doit faire nuit noire pour présenter le spectacle, la tenue d'un second spectacle serait beaucoup trop tard, compte tenu qu'il
            s'adresse à toute la famille.
          </p>

          <h3 class="regular-title">À quel endroit aura lieu BLEU : Le Québec sous les étoiles&nbsp;?</h3>
          <p class="regular-text">
            Le spectacle sera diffusé sur la façade du Parlement de Québec, en plein cœur de la colline parlementaire de Québec et à la limite de
            l’arrondissement historique du Vieux-Québec.
          </p>

          <h3 class="regular-title">Pourquoi avoir choisi le nom BLEU?</h3>
          <p class="regular-text">
            BLEU, c'est la couleur du Québec. À cause du drapeau bien sûr, mais aussi à cause du territoire. Le Québec, c'est d'abord son majestueux
            fleuve, porte d'entrée et voie communication depuis le début des temps. C’'est aussi un pays de lacs et de rivières. Au cours de ce récit, nos
            personnages emprunteront tous ces cours d'eau, de même que le ciel et l'espace.
          </p>

          <h3 class="regular-title">Pourquoi avoir choisi le Parlement comme lieu de diffusion&nbsp;?</h3>
          <p class="regular-text">
            Cet édifice patrimonial dessiné par Eugène-Étienne Taché, créateur de la devise Je me souviens, est intimement lié à l’histoire politique
            québécoise. Ce site à forte valeur symbolique saura ainsi créer un axe de résonance direct avec les spectateurs.
          </p>

          <h3 class="regular-title">Combien de personnes pouvez-vous accueillir sur le site du Parlement de Québec&nbsp;?</h3>
          <p class="regular-text">Le site du Parlement pourra accueillir plus de 10 000 personnes par soir.</p>

          <h3 class="regular-title">Qui a choisi les artistes figurant dans la projection?</h3>
          <p class="regular-text">Tant le contenu du spectacle que les artistes ont été sélectionnés par TKNL.</p>

          <h3 class="regular-title">Est-ce possible de visionner le spectacle sur le Web&nbsp;?</h3>
          <p class="regular-text">Non, le spectacle multimédia est uniquement diffusé sur le site, en personne.</p>

          <h3 class="regular-title">Quels ont été vos critères pour les choix d’artistes participant au spectacle&nbsp;?</h3>
          <p class="regular-text">
            Nous avons choisi des artistes qui contribuent à créer une trame narrative forte et émouvante et qui sont représentatifs de la diversité
            culturelle québécoise.
          </p>

          <h3 class="regular-title">Est-ce que les œuvres musicales ont été créées pour l’occasion&nbsp;?</h3>
          <p class="regular-text">
            La majeure partie de la trame musicale a été créée pour le spectacle. Des pièces ont été composées spécifiquement et des pièces du répertoire
            ont été revisitées au niveau de l’interprétation.
          </p>

          <h3 class="regular-title">Quel est l’objectif de ce spectacle&nbsp;?</h3>
          <p class="regular-text">
            L’objectif de ce spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs.
          </p>

          <h3 class="regular-title">Est-ce que les projections auront lieu même en cas de pluie&nbsp;?</h3>
          <p class="regular-text">
            Le spectacle pourra être présenté lors de faible pluie. Dans le cas de pluies torrentielles ou orage électrique qui forceraient l’annulation
            d’une représentation, l’information sera communiquée sur l’événement Facebook. Nous vous invitons à consulter la météo avant de vous déplacer.
          </p>

          <h3 class="regular-title">Faut-il se procurer un billet pour assister aux projections&nbsp;?</h3>
          <p class="regular-text">Non, le spectacle est gratuit et accessible à tous!</p>

          <h3 class="regular-title">Est-ce que des stationnements seront accessibles près du site&nbsp;?</h3>
          <p class="regular-text">Des stationnements publics et parcomètres sont accessibles dans les rues environnantes du site de spectacle.</p>

          <h3 class="regular-title">Est-ce que les rues seront fermées à la circulation?</h3>
          <p class="regular-text">
            L’Avenue Honoré-Mercier, entre la Grande-Allée et le boulevard René-Lévesque, pourrait fermer avant et pendant les représentations si la
            sécurité des gens le requiert.
          </p>

          <h3 class="regular-title">Est-ce que les enfants sont les bienvenus&nbsp;?</h3>
          <p class="regular-text">Absolument! Le spectacle est adapté pour les familles.</p>

          <h3 class="regular-title">Est-ce qu’il y aura une zone réservée aux personnes à mobilité réduite&nbsp;?</h3>
          <p class="regular-text">Oui, une section sera réservée pour les personnes à mobilité réduite.</p>

          <h3 class="regular-title">Est-ce la même œuvre qui est présentée chaque soir&nbsp;?</h3>
          <p class="regular-text">Oui. C’est le même spectacle qui sera présenté les 18, 19, 20, 21, 22 et 24 juin 2022.</p>

          <h3 class="regular-title">Y’aura-t-il des comptoirs offrant nourriture et boissons à même le site&nbsp;?</h3>
          <p class="regular-text">
            Aucune offre alimentaire ne sera offerte sur le site. Nous vous invitons à encourager les commerces locaux environnants!
          </p>

          <h3 class="regular-title">Y’aura-t-il des toilettes sur place?</h3>
          <p class="regular-text">Oui. Des toilettes sèches seront disponibles au public sur le site.</p>

          <h3 class="regular-title">Est-il possible d’apporter ses propres consommations&nbsp;?</h3>
          <p class="regular-text">Il est possible d’apporter ses propres boissons non alcoolisées.</p>

          <h3 class="regular-title">Est-ce possible de réserver ma place&nbsp;?</h3>
          <p class="regular-text">Le spectacle étant gratuit et accessible pour tous, il n’est pas possible de réserver sa place. À noter que le site est vaste et pourra accueillir plus de 10 000 spectateurs par soir. Il est conseillé d'arriver tôt sur place pour vous assurer d'avoir une bonne vue du spectacle.</p>
          
          <h3 class="regular-title">Comment puis-je me rendre sur le site du spectacle&nbsp;?</h3>
          <p class="regular-text">Le site est très bien desservi par le réseau de transport en commun, qu'il est fortement encouragé d'utiliser ! Si vous vous déplacez en voiture, plusieurs stationnements payants sont disponibles à proximité du site. </p>

          <h3 class="regular-title">Puis-je apporter ma chaise&nbsp;?</h3>
          <p class="regular-text">Vous pouvez apporter votre chaise. Sachez toutefois que les spectateurs seront libres de rester debout s'ils le souhaitent et cela pourrait bloquer la vue des personnes assises.</p>
        </div>
      </div>
    </article>

    <!-- EN -->
    <article class="terms" v-if="$route.meta.lang === 'en'">
      <div class="content">
        <h2 class="title">Event site map</h2>
        <a
            href="/static/site-map.pdf"
            target="_blank"
            class="text"
            ><img class="full-img" src="/static/site-map.jpg" alt="Event site map Bleu spectacle multimédia"></a>
        <h2 class="title">Q&A : GENERAL</h2>
        <div class="regular-text">
          <h3 class="regular-title">What is BLEU : Le Québec sous les étoiles? </h3>
          <p class="regular-text">BLEU : Le Québec sous les étoiles is an impressive, immersive and colourful new multimedia show presented on the Fête Nationale. </p>

          <h3 class="regular-title">When will the show take place?</h3>
          <p class="regular-text">The show will run from June 18 to 24 (with no presentation on June 23).</p>

          <h3 class="regular-title">What time does the show start?</h3>
          <p class="regular-text">The show will begin at 9:30 p.m. It starts at the same time every night.</p>

          <h3 class="regular-title">How long is the show?</h3>
          <p class="regular-text">The show lasts about 20 minutes. </p>

          <h3 class="regular-title">Why does the show only play once per night?</h3>
          <p class="regular-text">Since the show can only be presented when it's dark out, the second show would be too late, considering that it's intended for the whole family.</p>

          <h3 class="regular-title">Where will the show take place?</h3>
          <p class="regular-text">BLEU : Le Québec sous les étoiles will be projected on the façade of the National Assembly building, located just outside the Old Québec historic district.</p>

          <h3 class="regular-title">Where does the show’s name come from?</h3>
          <p class="regular-text">BLEU—or blue—is the colour of Québec. It’s on the flag and its embedded in Québec’s geography. Québec sits astride the majestic St. Lawrence, the gateway to the interior since the beginning of time. Québec is also a land dotted with lakes and streams. In the story of Bleu, the characters travel across all these waterways, as well as the sky and outer space.</p>

          <h3 class="regular-title">Why did you choose the National Assembly building as the backdrop?</h3>
          <p class="regular-text">This heritage building—which was designed by Eugène-Étienne Taché, the originator of the motto “Je me souviens”—is tied to Québec's political history. The site’s symbolic significance adds another layer of meaning to the show.</p>

          <h3 class="regular-title">What's the maximum capacity in front of the National Assembly?</h3>
          <p class="regular-text">The site outside the National Assembly building can accommodate more than 10,000 people per evening.</p>

          <h3 class="regular-title">Who selected the artists featured in the show? </h3>
          <p class="regular-text">Both the show’s content and the contributing artists were selected by TKNL.</p>

          <h3 class="regular-title">Can viewers watch the show online?</h3>
          <p class="regular-text">No, the multimedia presentation will only play on site before a live audience. </p>

          <h3 class="regular-title">What were your criteria when selecting artists for the show?</h3>
          <p class="regular-text">We looked for artists who are representative of Québec's cultural diversity and could contribute to a powerful and moving narrative.</p>

          <h3 class="regular-title">Was the music composed specially for the show?</h3>
          <p class="regular-text">Most of the soundtrack was created for the show. Some pieces were composed specifically for this production and some back-catalogue works were revisited. </p>

          <h3 class="regular-title">What is the show's key objective?</h3>
          <p class="regular-text">The show aims to generate a sense of pride among viewers and provide them with a memorable multimedia experience. </p>

          <h3 class="regular-title">Will the show still go on if it rains?</h3>
          <p class="regular-text">The show will be presented in the event of light rain. However, it will be cancelled if there is heavy rainl or a thunderstorm. Any cancellations will be announced on the Facebook event page. Spectators are encouraged to check the weather forecast before going to the show. </p>

          <h3 class="regular-title">Do I need a ticket to attend the show?</h3>
          <p class="regular-text">No, the show is free and open to all! </p>

          <h3 class="regular-title">Is there parking available near the site?</h3>
          <p class="regular-text">There are public parking lots and metered street parking near the venue. </p>

          <h3 class="regular-title">Will streets be closed to traffic?</h3>
          <p class="regular-text">Honoré-Mercier Avenue may be closed before and during performances between Grande-Allée and René-Lévesque Boulevard, if necessary for public safety.</p>

          <h3 class="regular-title">Can children attend?</h3>
          <p class="regular-text">Absolutely! The show is family-friendly. </p>

          <h3 class="regular-title">Will there be an area reserved for people with reduced mobility?</h3>
          <p class="regular-text">Yes, there will be a special section for people with reduced mobility. </p>

          <h3 class="regular-title">Is the same show presented every night?</h3>
          <p class="regular-text">Yes. The same show will be presented on June 18, 19, 20, 21, 22 and 24, 2022. </p>

          <h3 class="regular-title">Will there be food and beverage concessions on site?</h3>
          <p class="regular-text">No food will be sold on site. We encourage spectators to support the businesses in the area! </p>

          <h3 class="regular-title">Will there be restrooms on site?</h3>
          <p class="regular-text">Yes. Chemical toilets will be available on site for the public's use. </p>

          <h3 class="regular-title">Can you bring your own drinks?</h3>
          <p class="regulat-text">Yes, you can bring your own non-alcoholic beverages. </p>

          <h3 class="regular-title">Is it possible to reserve my seat?</h3>
          <p class="regulat-text">As the show is free and accessible to all, it is not possible to reserve a seat. Please note that the site is large and can accommodate more than 10,000 spectators per night. It is recommended to arrive early to ensure a good view of the show. </p>

          <h3 class="regular-title">How do I get to the show site?</h3>
          <p class="regulat-text">The site is very well served by public transportation, which is strongly encouraged! If you are traveling by car, there are several paid parking lots near the site. </p> 

          <h3 class="regular-title">Can I bring my chair?</h3>
          <p class="regulat-text">You may bring your own chair. Please be aware that spectators will be free to stand if they wish and this may block the view of those seated. </p>
        </div>
      </div>
    </article>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer'

export default {
  head() {
    return {
      title: {
        inner: 'FAQ',
      },
      meta: [
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {}
  },

  mounted() {},

  computed: mapState('app', ['appTitle']),

  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
