<template>
  <div class="page-wrapper">
    <img src="@/assets/img/bleu.png" alt="Logo Bleu" class="logo" />

    <section class="hero">
      <h2 class="text medium caps mobile-only">Quebec At nightfall </h2>
      <div class="content">
        <h1 class="hero-title">National <br/> Assembly</h1>
        <p class="hero-title right-align">
          June 18 <small>to</small> 22 <br/> <small>and</small> June 24
        </p>
      </div>
      <h2 class="text medium caps hero-bottom-title">Le Québec sous les étoiles</h2>
      <img src="@/assets/img/logo-qc-en.svg" alt="Logo Québec" class="logo-qc" />
      <img src="@/assets/img/arrow-down.svg" alt="arrow" class="scroll" />

      <img src="@/assets/img/hero-dots-1.png" alt="étoiles" class="dots full-h" data-gsap-scroll-speed-1 />
      <img src="@/assets/img/hero-dots-2.png" alt="étoiles" class="dots full-h" data-gsap-scroll-speed-2 />
      <img src="@/assets/img/hero.jpg" alt="Quebec At nightfall " class="background full-h" />
    </section>

    <section class="informations">
      <div class="title-wrap">
        <h2 class="title">IMPORTANT INFORMATION</h2>
      </div>
      <div class="icon-wrap">
        <div class="icon">
          <img src="@/assets/img/icon-map.svg" alt="Icon map" />
          <h3 class="text medium">LOCATION</h3>
          <p class="text">National Assembly</p>
          <address class="text small">1045 Rue des Parlementaires, Québec City, Québec  G1A 1A3</address>
          <a
            href="https://www.google.com/maps/dir//Parliament+Building,+1045+Rue+des+Parlementaires,+Quebec+City,+QC+G1A+1A3/@46.8086891,-71.2164144,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cb895d9b44dd8d5:0x6bfbd58d64f5475f!2m2!1d-71.2142257!2d46.8086891!3e0"
            target="_blank"
            class="text"
            >Directions</a
          ><br/>
          <a
            href="/static/site-map.pdf"
            target="_blank"
            class="text"
            >Event site map</a
          >
        </div>
        <div class="icon">
          <img src="@/assets/img/icon-calendar.svg" alt="Icon calendar" />
          <h3 class="text medium">DATE</h3>
          <p class="text">
            June 18 <small>to</small> 22 <br/>
            <small>and</small> June 24, 2022
          </p>
        </div>
        <div class="icon">
          <img src="@/assets/img/icon-time.svg" alt="Icon Time" />
          <h3 class="text medium">TIME</h3>
          <p class="text">At nightfall<br />(around 9:30 p.m.)</p>
        </div>
        <div class="icon">
          <img src="@/assets/img/icon-smile.svg" alt="Icon Smile" />
          <h3 class="text medium">GRATUIT</h3>
          <p class="text">A family-friendly event <br/> that's open to everyone.</p>
        </div>
      </div>
      <div class="meteo-wrap">
        <img src="@/assets/img/icon-weather.svg" alt="Icon Weather" />
        <p class="text">
         Please check the weather before coming. The show may be cancelled in the event of heavy rain or thunderstorms. Follow us on <a href="https://www.facebook.com/events/722871948896240/722871965562905/" target="_blank">social media</a> to keep up with our latest news.
        </p>
      </div>
      <img src="@/assets/img/canot.png" alt="" class="canot" data-gsap-canot />
    </section>

    <section class="description">
      <h2 class="title">A JOURNEY THROUGH THE HIGHLIGHTS OF QUÉBEC'S HISTORY</h2>
      <div class="data">
        <div class="left">
          <h3 class="text medium">
            A new multimedia show presented <br/> for free on Québec's national holiday
          </h3>
          <div class="video">
            <a href="https://youtu.be/Zjr4RhfAzOo" class="glightbox"><img src="@/assets/img/play.svg" alt="play" class="play" /></a>
            <img src="@/assets/img/video.jpg" alt="Video" class="video-img" />
          </div>
        </div>
        <div class="right">
          <p class="text">
            <strong>BLEU : Le Québec sous les étoiles</strong> is an impressive and colourful new multimedia show presented by the <strong>Québec government</strong>. Projected on the façade of the <strong>National Assembly building on June 18 to 22 and June 24</strong>, this captivating multimedia production celebrates the Fête Nationale by taking spectators on a journey through Québec's landscapes, language, history and culture.
            <br /><br />
            <strong>BLEU : Le Québec sous les étoiles</strong>is a story that oscillates between magic and majesty, adventures and encounters. We follow a grandmother, played by France Castel, and her grandson on a canoe trip across Québec. Their epic journey takes them through an imaginary world filled with the best of Québec's past and present, from the landscapes to the culture and the people who shaped Québec and left their mark on its history. A feel-good show about Québec's art, history, people, land and natural beauty that will resonate with young and old alike and evoke memories rooted in each viewer’s personal experiences.
          </p>
          <br /><br />
          <a href="https://www.facebook.com/events/722871948896240/722871965562905/" target="_blank">
            <p class="text">Follow us</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.079" viewBox="0 0 22.079 47.312">
              <g id="Group_129" data-name="Group 129" transform="translate(-151.343 -5053.242)">
                <path id="Path_135" data-name="Path 135" d="M171.845,5081.354h-20.5v-9.462h22.079Z" transform="translate(0 -2.878)" />
                <path
                  id="Facebook"
                  d="M156.989,5062.77v37.785h9.419v-36.273a1.428,1.428,0,0,1,1.577-1.577h6.309v-9.462H167.03C156.745,5053.242,156.989,5061.533,156.989,5062.77Z"
                  transform="translate(-0.871)"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
      <img src="@/assets/img/canot-dots-1.png" alt="étoiles" class="dots" data-gsap-scroll-speed-1 />
      <img src="@/assets/img/canot-dots-2.png" alt="étoiles" class="dots" data-gsap-scroll-speed-2 />
    </section>

    <section class="testimonials">
      <div class="title-wrap">
        <h2 class="title green">TESTIMONIALS</h2>
      </div>
      <div class="main-carousel">
        <div class="carousel-cell">
          <p class="text">
            « BLEU is a digital fresco of Québec, a fantastical journey through its history, landscapes, culture and people. It’s an exciting show that brings people together! »<br /><br /><span>Nathalie Gélinas, producer at TKNL</span>
          </p>
        </div>
        <div class="carousel-cell">
          <p class="text">
            « As always, song acts as the common thread, spice and punctuation for our history. In BLEU, it reflects the Québec's soul and ours as well. »<br /><br /><span> Monique Giroux, radio host</span>
          </p>
        </div>
        <div class="carousel-cell">
          <p class="text">
            « Presented under the stars, BLEU is a touching and larger-than-life multimedia show. The narrative oscillates between magic and majesty, adventures and encounters. »<br /><br /><span> Alexis Laurence, artistic director of Le Studio LEX</span>
          </p>
        </div>
        <div class="carousel-cell">
          <p class="text">
            « The soundtrack to BLEU is both grand and intimate. With input from talented local artists, it takes us on a journey through the beauty of our cultural heritage! »<br /><br /><span> Marc Bell, composer and director, Troublemakers</span>
          </p>
        </div>
      </div>
    </section>

    <section class="partenaires">
      <h2 class="text medium caps">PRESS</h2>

      <div class="logos-wrap">
        <div class="logo-partenaire big">
          <a href="https://www.quebec.ca/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/logo-qc-en.svg" alt="Logo Québec" /></a>
        </div>
        <div class="logo-partenaire">
          <a href="http://www.assnat.qc.ca/fr/index.html" target="_blank" rel="noopener noreferrer"
            ><img src="@/assets/img/assemble-en.png" alt="Logo Assemblée nationnale Québec"
          /></a>
        </div>
        <div class="logo-partenaire">
          <a href="https://www.tknl.com/" target="_blank" rel="noopener noreferrer"
            ><img src="@/assets/img/logo-tknl-en.png" alt="Logo TKNL créateurs d'expériences"
          /></a>
        </div>
        <div class="logo-partenaire">
          <a href="https://www.gestev.com/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/logo-gestev-en.svg" alt="Logo Gestev" /></a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'
import Flickity from 'flickity'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Footer from '@/components/Footer'

gsap.registerPlugin(ScrollTrigger)

export default {
  head() {
    return {
      title: {
        inner: 'Le Québec sous les étoiles',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Le Québec sous les étoiles` },
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `Le Québec sous les étoiles` },
        {
          n: 'twitter:description',
          c: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Le Québec sous les étoiles` },
        { property: 'og:site_name', content: `Le Québec sous les étoiles` },
        { property: 'og:type', content: `Website` },
        {
          property: 'og:description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
        },
        // Og Image
        {
          p: 'og:image',
          c: '/static/og-img.png',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {
      teamCarousel: null,
    }
  },

  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()

    setTimeout(() => {
      this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
        wrapAround: true,
        imagesLoaded: true,
        prevNextButtons: true,
        pageDots: true,
        autoPlay: 7500,
      })

      this.initGsap()
    }, 1055)
  },

  computed: mapState('app', ['appTitle']),

  methods: {
    initGsap() {
      gsap.utils.toArray('[data-gsap-scroll-speed-1]').forEach((element) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top top',
            end: 'bottom top',
            scrub: 2.475,
          },
          y: '20%',
          ease: 'none',
        })
      })
      gsap.utils.toArray('[data-gsap-scroll-speed-2]').forEach((element) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top top',
            end: 'bottom top',
            scrub: 1.475,
          },
          y: '-40%',
          ease: 'none',
        })
      })
      gsap.utils.toArray('[data-gsap-canot]').forEach((element) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1,
          },
          x: '40vw',
          ease: 'none',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
