import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import HomeEn from '@/views/HomeEn'
import Credits from '@/views/Credits'
import Politiques from '@/views/Politiques'
import Reglements from '@/views/Reglements'
import Medias from '@/views/Medias'
import Faq from '@/views/Faq'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE,
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        lang: 'fr'
      }
    },
    {
      path: '/credits',
      name: 'credits',
      component: Credits,
      meta: {
        title: 'Credits',
        isTerms: true,
        lang: 'fr'
      },
    },
    {
      path: '/politiques',
      name: 'politiques',
      component: Politiques,
      meta: {
        title: 'Politiques',
        isTerms: true,
        lang: 'fr'
      },
    },
    {
      path: '/reglements',
      name: 'reglements',
      component: Reglements,
      meta: {
        title: 'Règlements',
        isTerms: true,
        lang: 'fr'
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: Faq,
      meta: {
        title: 'FAQ',
        isTerms: true,
        lang: 'fr'
      },
    },
    {
      path: '/medias',
      name: 'medias',
      component: Medias,
      meta: {
        title: 'Médias',
        isTerms: true,
        lang: 'fr'
      },
    },

    {
      path: '/en',
      name: 'home-en',
      component: HomeEn,
      meta: {
        lang: 'en',
      }
    },
    {
      path: '/en/credits',
      name: 'credits-en',
      component: Credits,
      meta: {
        title: 'Credits',
        isTerms: true,
        lang: 'en',
      },
    },
    {
      path: '/en/policy',
      name: 'politiques-en',
      component: Politiques,
      meta: {
        title: 'Politiques',
        isTerms: true,
        lang: 'en',
      },
    },
    {
      path: '/en/rules',
      name: 'reglements-en',
      component: Reglements,
      meta: {
        title: 'Règlements',
        isTerms: true,
        lang: 'en',
      },
    },
    {
      path: '/en/faq',
      name: 'faq-en',
      component: Faq,
      meta: {
        title: 'FAQ',
        isTerms: true,
        lang: 'en',
      },
    },
    {
      path: '/en/press',
      name: 'medias-en',
      component: Medias,
      meta: {
        title: 'Médias',
        isTerms: true,
        lang: 'en',
      },
    },

    { path: '*', redirect: '/' },
  ],
})

export default router
