<template>
  <div class="page-wrapper">

    <!-- FR -->
    <article class="terms" v-if="$route.meta.lang === 'fr'">
      <div class="content">
        <h2 class="title">Règlements</h2>
        <div class="regular-text">
          <p class="regular-text">Le site de l’événement ne sera pas clôturé. L’accès est donc libre.</p>

          Quelques consignes seront toutefois applicables :
          <ul>
            <li><strong>Le participant devra accepter de se conformer aux directives et normes sanitaires en vigueur au moment de la projection. Tout refus ou défaut de se conformer aux directives et normes sanitaires forcera l’expulsion du participant du site de l’événement;</strong></li>
            <li>Obligation de garder les animaux en laisse;</li>
            <li>Interdiction de prendre des photos et/ou des vidéos pendant la projection et/ou de faire la captation du spectacle sous tout forme que ce soit;</li>
            <li>Interdiction de manipuler ou d’utiliser des pointeurs laser de toute nature et/ou intensité;</li>
            <li>Interdiction de manipuler ou de lancer des feux d’artifice, feux de Bengale ou pétards;</li>
            <li>Interdiction de manipuler des items pouvant poser un danger à la sécurité des autres spectateurs;</li>
            <li>Aucune consommation d'alcool ou de cannabis ne sera tolérée;</li>
            <li>Il est recommandé de ne pas utiliser de parapluie ni de chaise afin de ne pas obstruer la vue et maximiser l’espace pour les spectateurs;</li>
            </ul>

            <br/><br/>

            Autres considérations :
            <ul>
              <li>Aucune vente de breuvage, d’eau, de nourriture ou d’alcool ne sera faite sur place;</li>
              <li>Des toilettes chimiques seront accessibles sur le site pendant les représentations;</li>
              <li>Accessibilité et espace réservé pour les personnes à mobilité réduite</li>
              <li>Aucun stationnement sur le site;</li>
            </ul>

        </div>
      </div>
    </article>

    <!-- EN -->
    <article class="terms" v-if="$route.meta.lang === 'en'">
      <div class="content">
        <h2 class="title">Rules</h2>
        <div class="regular-text">
          <p class="regular-text">The event site will not be fenced off. People will be able to enter freely.</p>

          However, persons accessing the site must abide by the following rules:
          <ul>
            <li><strong>Attendees must comply with all public health directives and regulations in effect at the time of the show. Anyone who refuses or fails to comply with public health directives and regulations will be asked to leave or removed from the site.</strong></li>
            <li>All pets must be kept on leash.</li>
            <li>Taking photos, videos and/or any other recordings of the show is strictly prohibited.</li>
            <li>Handling or using laser pointers of any type and/or intensity is strictly prohibited.</li>
            <li>Handling or throwing fireworks, flares or firecrackers is strictly prohibited.</li>
            <li>Handling items that could threaten the safety of other spectators is strictly prohibited.</li>
            <li>The consumption of alcohol or cannabis will not be tolerated.</li>
            <li>The use of umbrellas and/or chairs is not recommended, as these items could obstruct the view and limit the amount of space available to other spectators.</li>

            </ul>

            <br/><br/>

            Other considerations:
            <ul>
              <li>No beverages, water, food or alcohol may be sold on site.</li>
              <li>Chemical toilets will be available on site during the show.</li>
              <li>There will be a special section for people with reduced mobility. </li>
              <li>No parking is available on site.</li>
            </ul>
        </div>
      </div>
    </article>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer'

export default {
  head() {
    return {
      title: {
        inner: 'Règlements',
      },
      meta: [
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {}
  },

  mounted() {},

  computed: mapState('app', ['appTitle']),

  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
