<template>
  <div class="page-wrapper">

    <!-- FR -->
    <article class="terms" v-if="$route.meta.lang === 'fr'">
      <div class="content">
        <h2 class="title">Médias</h2>
        <div class="regular-text">
          <p class="regular-text">
            Vous souhaitez couvrir BLEU : Le Québec sous les étoiles? Contactez-nous!
            <br /><br />
            <strong>Melissa Parent</strong>
            Conseillère Communications <br />
            <a href="mailto:mparent@gestev.com">mparent@gestev.com</a> <br />
            <a href="tel:4189314068">418-931-4068</a>
          </p>
        </div>
      </div>
    </article>

    <!-- EN -->
    <article class="terms" v-if="$route.meta.lang === 'en'">
      <div class="content">
        <h2 class="title">PRESS</h2>
        <div class="regular-text">
          <p class="regular-text">
            Are you interested in covering BLEU : Le Québec sous les étoiles? Contact us!
            <br /><br />
            <strong>Melissa Parent</strong>
            Communications Advisor  <br />
            <a href="mailto:mparent@gestev.com">mparent@gestev.com</a> <br />
            <a href="tel:4189314068">418-931-4068</a>
          </p>
        </div>
      </div>
    </article>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer'

export default {
  head() {
    return {
      title: {
        inner: 'Médias',
      },
      meta: [
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {}
  },

  mounted() {},

  computed: mapState('app', ['appTitle']),

  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
