<template>
  <div class="page-wrapper">

    <!-- FR -->
    <article class="terms" v-if="$route.meta.lang === 'fr'">
      <div class="content">
        <h2 class="title">Crédits</h2>
        <div class="regular-text">
          <h3 class="medium-title">
            SPECTACLE MULTIMÉDIA <br />
            <strong>BLEU</strong> LE QUÉBEC SOUS LES ÉTOILES
          </h3>

          <span>Présenté par</span>
          <img src="https://medac.qc.ca/images/qcinc.svg" alt="" />
          <br /><br />
          <span>Une production de</span>
          <img
            src="https://media-exp1.licdn.com/dms/image/C4D0BAQFYHbwWj68jsA/company-logo_200_200/0/1617145904426?e=1659571200&v=beta&t=VuHH_keO012FO1SKomRJn6awsWtFj80V7U3Bi6GYQDs"
            alt=""
          />

          <br /><br />
          <h3 class="medium-title">Une production</h3>

          <strong>Productrice</strong> <br />
          Nathalie Gélinas<br /><br />

          <strong>Directeur artistique</strong><br />
          Alexis Laurence (Le studio LEX) <br /><br />

          <strong>Réalisateur au contenu</strong><br />
          Mario Landry<br /><br />

          <strong>Directrices de production</strong> <br />
          Claude Bourgault<br />
          Lysanne Côté<br />
          Geneviève Leclair <br /><br />

          <strong>Coordonnatrice de production</strong><br />
          Charlotte Wasser<br /><br />

          <strong>Dialoguiste</strong><br />
          Frédéric Bélanger<br /><br />

          <strong>Storyboard</strong><br />
          Robert Massicotte<br />
          Monteur image<br />
          Hubert R. Deroy<br />

          <br /><br />
          <h3 class="medium-title">Concepteurs des animations</h3>
          Dovi Kotto Maka (Le studio LEX)<br />
          Carine Khalifé <br />
          Mathieu Lalumière <br />
          Sophie Lévesque<br />
          Benoit Martel<br />
          El Toro studio

          <br /><br />
          <h3 class="medium-title">Distribution</h3>

          Grand-mère: France Castel<br />
          Petit garçon: Mathis Pommainville

          <br /><br />
          <h3 class="medium-title">Figurants par ordre d’apparition</h3>
          La jeune amoureuse: Ines Defossé <br />
          Le jeune amoureux: William Lapierre <br />
          L’homme âgé: Paul Marcil<br />
          La femme âgée: Monique Bastien<br />
          L’acrobate: Karin Arseneault <br />
          La petite fille à la camisole: Cléo Alexis <br />
          La petite fille à la robe jaune: Ophélie Ouellette <br />
          La maman des petites filles: Émilie Ouellette <br />
          Le papa des petites filles: Mc Knoell Alexis <br />
          Le danseur: Simon-Xavier Lefebvre<br />

          <br /><br />
          <h3 class="medium-title">Trame sonore et musicale</h3>

          <h3 class="medium-title">Musique originale par Troublemakers</h3>

          Composition & direction musicale : Marc Bell<br />
          Producteur : Manu Alberola<br />
          Programmation, synthétiseurs, percussions : Marc Bell<br />
          Batterie : Fred Bouchard<br />
          Basse : Alexandre Lapointe<br />
          Guitare électrique : Jocelyn Tellier<br />
          Chants de gorge : Nina Segalowitz<br />
          Violons : Mélanie Vaugeois et Mélanie Bélair<br />
          Violon alto : Ligia Paquin<br />
          Violoncelle : Annie Gadbois<br />

          <br /><br />

          <h3 class="medium-title">Concepteur des effets sonores, ingénieur de son et concepteur de la spatialisation</h3>
          David Fournier<br />
          <img src="https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6057ba5f2d308d0001f8517c/picture" alt="" />

          <strong>Conseillère musicale</strong><br />
          Monique Giroux<br /><br />

          <strong>Négociation des droits</strong><br />
          Lucie Bourgouin<br /><br />

          <strong>“Rivière”</strong><br />
          Auteur-compositeur : Michel Rivard<br />
          Les Éditions Sauvages<br /><br />

          <strong>“La Manic”</strong> <br />
          Auteur-compositeur : George D’Or<br />
          Interprète: George D’Or<br />
          Unidisc Music inc.<br /><br />

          <strong>“Changing winds”</strong><br />
          Composé par Alexandra Stréliski<br />
          Interprète : Alexandra Stréliski<br />
          Les Éditions Secret City Records<br /><br />

          <strong>Slam “La tempête”</strong><br />
          Auteur et interprète : David Goudreault<br /><br />

          <strong>“Tout le monde en même temps”</strong><br />
          Compositeur : Louis-Jean Cormier<br />
          Auteur : Daniel Beaumont<br />
          Éditions : Les Yeux Boussoles représentées par David Murphy et Cie <br />
          Arrangements : Louis-Jean Cormier et Martha Wainwright<br />
          Interprètes : Louis-Jean Cormier et Martha Wainwright<br />
          Guitares : Louis-Jean Cormier<br />
          Basse : Amélie Mandeville<br />
          Drums : Jean-Phi Goncalves<br />
          Claviers : François Lafontaine<br /><br />

          <strong>“Les gens de mon pays”</strong><br />
          Auteur-compositeur : Gilles Vigneault<br />
          Les Éditions du Vent qui Vire<br />
          Arrangements :Ariane Moffatt & Marc Bell<br />
          Interprètes: Ariane Moffatt, La Bronze & Samian<br />
          Piano & synthétiseurs: Ariane Moffatt<br />

          <br /><br />
          <h3 class="medium-title">Équipe de tournage</h3>

          <strong>Directeur photo et cadreur</strong><br />
          Luc Robida<br /><br />

          <strong>Assistant caméra et éclairagiste</strong><br />
          Marc Warden<br /><br />

          <strong>Assistant à la réalisation</strong><br />
          Hubert R. Deroy<br /><br />

          <strong>Preneur de son</strong> <br />
          Jean-François Paradis<br /><br />

          <strong>Maquilleuses</strong><br />
          Francine Gagnon<br />
          Ève Langlais<br /><br />

          <strong>Accessoiriste</strong><br />
          François Locas<br /><br />

          <strong>Habilleur</strong><br />
          Cédric Quenneville<br />

          <br /><br />
          <h3 class="medium-title">Équipe technique pour le spectacle</h3>

          <h3 class="medium-title">Une conception technique</h3>
          <img
            src="https://media-exp1.licdn.com/dms/image/C4D0BAQFYHbwWj68jsA/company-logo_200_200/0/1617145904426?e=1659571200&v=beta&t=VuHH_keO012FO1SKomRJn6awsWtFj80V7U3Bi6GYQDs"
            alt=""
          />

          <strong>Directeur technique et concepteur de la sonorisation</strong> <br />
          David Fournier<br /><br />

          <strong>Assistant directeur technique</strong><br />
          Mathieu Lamothe<br /><br />

          <strong>Chef vidéo</strong><br />
          Emmanuel Lissandre<br /><br />

          <strong>Concepteur des éclairages</strong> <br />
          Anthony Francoeur<br /><br />

          <strong>Programmeur watchout et mapping</strong><br />
          Nicolas Gendron<br /><br />

          <strong>Chef son</strong><br />
          Hugues Bourque<br /><br />

          <strong>Projectionniste</strong><br />
          Antoine Thibaut<br /><br />

          <strong>Chef distribution électrique</strong><br />
          Patrick Lapointe<br /><br />

          <strong>Technicien distribution électrique</strong><br />
          Jonathan Chevalier<br /><br />

          <strong>Opérateur watchout</strong><br />
          Yoann Lacasse<br /><br />

          <br /><br />
          <h3 class="medium-title">Équipe Gestev</h3>

          <strong>Directrice générale adjointe</strong><br />
          Chantale Boivin<br /><br />

          <strong>Directrice de site</strong><br />
          Caroline Cloutier-Gallant<br /><br />

          <strong>Communications et marketing</strong><br />
          Nicolas Célant<br />
          Valérie Gagné<br />
          Marie-Michelle Gagné<br />
          Mélissa Parent<br />
          Samantha Picard-Tham<br /><br />

          <strong>Directrice artistique</strong><br />
          Magalie Lajeunesse<br />

          <br /><br />
          <h3 class="medium-title">Pour leur contribution, nous remercions (en ordre alphabétique)</h3>

          Alex Bilodeau <br>
          Alex Harvey <br>
          Boucar Diouf <br>
          Chantal Petitclerc <br>
          Chloé Dufour-Lapointe <br>
          Dany Laferrière <br>
          David Saint-Jacques <br>
          Dominique Maltais <br>
          Farah Alibay <br>
          Fred Pellerin <br>
          Gaétan Boucher <br>
          Gilles Vigneault <br>
          Hélène Dorion <br>
          Janette Bertrand <br>
          Jean Lesage  <br>
          Joannie Rochette <br>
          Justine Dufour-Lapointe <br>
          Kim Thuy <br>
          Laurent Duvernay-Tardif <br>
          Leonard Cohen <br>
          Lucien Bouchard <br>
          Marianne St-Gelais <br>
          Marie Laberge <br>
          Marie-Philip Poulin <br>
          Maxime Dufour-Lapointe <br>
          Michel Chartrand <br>
          Michel Tremblay <br>
          Natasha Kanapé-Fontaine <br>
          René Lévesque <br>
          Robert Bourassa <br>
          Sébastien Toutant <br>
          Dr Stanley Vollant <br>
          Thérèse Casgrain


          <br /><br />
          <h3 class="medium-title">Sources des photos et oeuvres</h3>

          <strong>Phare Îles-de-la-Madeleine</strong> <br />
          Crédit photo : L’Île Imagin’air<br /><br />

          <strong>Plongeur</strong> <br />
          Crédit photo : Mickey Charteris<br /><br />

          <strong>Paysage Tourisme Québec</strong> <br />
          Crédit photo : Steve Deschênes<br /><br />

          <strong>Chute Vauréal</strong><br />
          Crédit photo : Simon Massicotte<br /><br />

          <strong>Le Diamant:</strong><br />
          Crédit photo : Stéphane Groleau<br /><br />

          <sgtorng>Champ coloré</sgtorng><br />
          Mario Faubert, Aviateur Photographe<br />
          <a target="_blank" href="https://www.airphotomax.com">www.airphotomax.com</a><br /><br />


          <strong>Paysage à Saint-Siméon 1938</strong> <br />
          Crédit photo: Marc-Aurèle Fortin<br />
          © Fondation Marc-Aurèle Fortin / SOCAN (2021)<br /><br />

          “Les coupeurs de glace” de Horatio Walker<br /><br />

          “Ice Fishing” de Dominique Normand<br /><br />

          “Thérèse Casgrain” de Adèle Blais<br /><br />

          <strong>Avec l’aimable autorisation des Éditions du Seuil</strong> <br />
          Les Fous de Bassan, Anne Hébert, © Éditions du Seuil, 1982, n.e, Points 1998 <br /><br />

          <strong>Bibliothèque et Archives nationales du Québec</strong><br />

          <strong>Archives de Montréal</strong><br />

          <strong>Portrait de Marie Laberge</strong><br />
          Crédit photo: Michel Cloutier<br /><br />

          <strong>Portrait de Kim Thúy</strong><br />
          Crédit photo: Lars von Törne / Der Tagesspiegel<br /><br />

          <strong>Portrait de Natasha Kanapé-Fontaine</strong><br />
          Crédit photo: Wapikoni mobile<br /><br />

          <strong>Portrait de Gilles Vigneault</strong><br />
          Crédit photo: MC2 Grenoble 

          <br /><br />
          <h3 class="medium-title">Extraits sonores</h3>

          <strong>Négociation des droits</strong><br />
          Sophie Moreau, LA NÉGO<br /><br />

          <strong>Lance et compte</strong><br />
          Producteur : GAEA Films<br />
          Scénariste : Réjean Tremblay<br />
          Comédien : Yvan Ponton<br /><br />

          <strong>Les Boys</strong> <br />
          Producteur : ComédiHa! Télévision <br />
          Scénariste : Louis Saia et Christian Fournier<br />
          Comédien : Marc Messier<br /><br />

          <strong>La guerre des tuques</strong> <br />
          Producteur : Productions la Fête<br />
          Scénariste : Roger Cantin et Danyèle Patenaude<br />
          Comédien : Mathieu Savard<br /><br />

          <strong>BROUE</strong><br />
          Producteur : Théâtre Les Voyagements & Les 3M<br />
          Auteur : Jean-Pierre Plante, Louis Saia, Claude Meunier, Francine Ruel, Michel Côté, Marc Messier et Marcel Gauthier<br />
          Comédien : Marc Messier<br /><br />

          <strong>La Petite Vie</strong><br />
          Producteur : Avanti groupe<br />
          Scénariste : Claude Meunier<br />
          Comédienne : Diane Lavallée<br /><br />

          <strong>Passe Partout</strong><br />
          Producteur : JPL Production et ministère de l’Éducation du Québec<br />
          Scénariste : Bernard Tanguay, Michel Poirier, Ronald Prégent<br />
          Comédiens : Mireille Lachance, Eve Gagnier, Jocelyne Goyette<br /><br />

          <strong>Watatatow</strong><br />
          Producteur : Vivavision<br /><br />

          <strong>Fanfreluche</strong><br />
          Producteur : Société Radio-Canada<br />
          Scénariste : Kim Yaroshevskaya<br />
          Comédienne : Kim Yaroshevskaya<br /><br />

          <strong>Groupe TVA Inc.</strong><br />
          Archives Radio-Canada<br />
          Télé-Québec<br />
        </div>
      </div>
    </article>

    <!-- EN -->
    <article class="terms" v-if="$route.meta.lang === 'en'">
      <div class="content">
        <h2 class="title">Credits</h2>
        <div class="regular-text">
          <h3 class="medium-title">
            Multimedia show <br />
            <strong>BLEU</strong> LE QUÉBEC SOUS LES ÉTOILES
          </h3>

          <span>Presented by</span>
          <img src="https://medac.qc.ca/images/qcinc.svg" alt="" />
          <br /><br />
          <span>Production</span>
          <img
            src="https://media-exp1.licdn.com/dms/image/C4D0BAQFYHbwWj68jsA/company-logo_200_200/0/1617145904426?e=1659571200&v=beta&t=VuHH_keO012FO1SKomRJn6awsWtFj80V7U3Bi6GYQDs"
            alt=""
          />

          <br /><br />
          <h3 class="medium-title">Une production</h3>

          <strong>Producer</strong> <br/>
          Nathalie Gélinas<br/><br/>

          <strong>Artistic director</strong><br/>
          Alexis Laurence (Le studio LEX) <br/><br/>

          <strong>Content director</strong><br/>
          Mario Landry<br/><br/>

          <strong>Production managers</strong><br/>
          Claude Bourgault<br/>
          Lysanne Côté<br/>
          Geneviève Leclair <br/><br/>

          <strong>Production coordinator</strong><br/>
          Charlotte Wasser<br/><br/>

          <strong>Dialogue</strong><br/>
          Frédéric Bélanger<br/><br/>

          <strong>Storyboard</strong><br/>
          Robert Massicotte<br/><br/>

          <strong>Image editor</strong><br/>
          Hubert R. Deroy<br/><br/>


          <br /><br />
          <h3 class="medium-title">Animation designers</h3>
          Dovi Kotto Maka (Le studio LEX)<br />
          Carine Khalifé <br />
          Mathieu Lalumière <br />
          Sophie Lévesque<br />
          Benoit Martel<br />
          El Toro studio

          <br /><br />
          <h3 class="medium-title">Cast</h3>

          Grandmother: France Castel<br />
          Little boy: Mathis Pommainville

          <br /><br />
          <h3 class="medium-title">Other roles</h3>
          Girl in love:	Ines Defossé   <br />
          Boy in love:	William Lapierre        <br />
          Old man:	Paul Marcil<br />
          Old woman:	Monique Bastien<br />
          Acrobat:	Karin Arseneault<br />
          Little girl in nightshirt:	Cléo Alexis   <br />
          Little girl in yellow dress:	Ophélie Ouellette   <br />
          Mother of little girls:	Émilie Ouellette   <br />
          Father of little girls:	Mc Knoell Alexis <br />
          Dancer:	Simon-Xavier Lefebvre<br /><br />


          <br /><br />
          <h3 class="medium-title">Soundtrack</h3>

          <h3 class="medium-title">Original music by Troublemakers</h3>

          Composition & musical direction: Marc Bell<br />
          Producer: Manu Alberola<br />
          Programming, synthesizers, percussion: Marc Bell<br />
          Drums: Fred Bouchard<br />
          Bass: Alexandre Lapointe<br />
          Electric guitar: Jocelyn Tellier<br />
          Throat singing: Nina Segalowitz<br />
          Violin: Mélanie Vaugeois and Mélanie Bélair<br />
          Viola: Ligia Paquin<br />
          Cello: Annie Gadbois

          <br /><br />

          <h3 class="medium-title">Sound effects designer, sound engineer and spatialization designer</h3>
          David Fournier<br />
          <img src="https://zenprospect-production.s3.amazonaws.com/uploads/pictures/6057ba5f2d308d0001f8517c/picture" alt="" />

          <strong>Music consultant</strong><br />
          Monique Giroux<br /><br />

          <strong>Rights negotiation</strong><br />
          Lucie Bourgouin<br /><br />

          <strong>“Rivière”</strong><br />
          Written by: Michel Rivard<br />
          Les Éditions Sauvages<br /><br />


          <strong>“La Manic”</strong> <br />
          Written by: George D’Or<br />
          Performed by: George D’Or<br />
          Unidisc Music inc.<br /><br />


          <strong>“Changing winds”</strong><br />
          Composed by: Alexandra Stréliski<br />
          Performed by:  Alexandra Stréliski<br />
          Les Éditions Secret City Records<br /><br />


          <strong>Slam “La tempête”</strong><br />
          Written and performed by: David Goudreault<br /><br />

          <strong>“Tout le monde en même temps”</strong><br />
          Composed by: Louis-Jean Cormier<br />
          Lyrics: Daniel Beaumont<br />
          Publisher: Les Yeux Boussoles, represented by David Murphy et Cie <br />
          Arrangements: Louis-Jean Cormier and Martha Wainwright<br />
          Performed by: Louis-Jean Cormier and Martha Wainwright<br />
          Guitar: Louis-Jean Cormier<br />
          Bass: Amélie Mandeville<br />
          Drums: Jean-Phi Goncalves<br />
          Keyboard: François Lafontaine<br /><br />


          <strong>“Les gens de mon pays”</strong><br />
          Written by: Gilles Vigneault<br />
          Les Éditions du Vent qui Vire<br />
          Arrangements: Ariane Moffatt and Marc Bell<br />
          Performed by: Ariane Moffatt, La Bronze and Samian<br />
          Piano and synthesizers: Ariane Moffatt


          <br /><br />
          <h3 class="medium-title">Film crew</h3>

          <strong>Director of photography and cameraman</strong><br />
          Luc Robida<br /><br />

          <strong>Camera assistant and lighting</strong><br />
          Marc Warden<br /><br />

          <strong>Assistant director</strong><br />
          Hubert R. Deroy<br /><br />

          <strong>Sound recorder </strong> <br />
          Jean-François Paradis<br /><br />

          <strong>Make-up artists</strong><br />
          Francine Gagnon<br />
          Ève Langlais<br /><br />

          <strong>Props</strong><br />
          François Locas<br /><br />

          <strong>Dresser</strong><br />
          Cédric Quenneville<br />

          <br /><br />
          <h3 class="medium-title">Technical team for the show</h3>

          <h3 class="medium-title">Technical design by</h3>
          <img
            src="https://media-exp1.licdn.com/dms/image/C4D0BAQFYHbwWj68jsA/company-logo_200_200/0/1617145904426?e=1659571200&v=beta&t=VuHH_keO012FO1SKomRJn6awsWtFj80V7U3Bi6GYQDs"
            alt=""
          />

          <strong>Technical director and sound designer</strong> <br />
          David Fournier<br /><br />

          <strong>Assistant technical director</strong><br />
          Mathieu Lamothe<br /><br />

          <strong>Head of video</strong><br />
          Emmanuel Lissandre<br /><br />

          <strong>Lighting designer</strong> <br />
          Anthony Francoeur<br /><br />

          <strong>Watchout programmer and mapping</strong><br />
          Nicolas Gendron<br /><br />

          <strong>Head of sound</strong><br />
          Hugues Bourque<br /><br />

          <strong>Projectionist</strong><br />
          Antoine Thibaut<br /><br />

          <strong>Head of electrical distribution</strong><br />
          Patrick Lapointe<br /><br />

          <strong>Electrical distribution technician</strong><br />
          Jonathan Chevalier<br /><br />

          <strong>Watchout operator</strong><br />
          Yoann Lacasse<br /><br />

          <br /><br />
          <h3 class="medium-title">Gestev team</h3>

          <strong>Assistant general manager</strong><br />
          Chantale Boivin<br /><br />

          <strong>Site director</strong><br />
          Caroline Cloutier-Gallant<br /><br />

          <strong>Communications and marketing</strong><br />
          Nicolas Célant<br />
          Valérie Gagné<br />
          Marie-Michelle Gagné<br />
          Mélissa Parent<br />
          Samantha Picard-Tham<br /><br />

          <strong>Artistic director</strong><br />
          Magalie Lajeunesse<br />

          <br /><br />
          <h3 class="medium-title">We thank for their contributions (in alphabetical order) </h3>

          Boucar Diouf<br />
          Chantal Petitclerc<br />
          Dany Laferrière<br />
          David Saint-Jacques<br />
          Farah Alibay<br />
          Fred Pellerin<br />
          Gilles Vigneault<br />
          Hélène Dorion<br />
          Janette Bertrand<br />
          Jean Lesage <br />
          Kim Thuy<br />
          Laurent Duvernay-Tardif<br />
          Leonard Cohen<br />
          Lucien Bouchard<br />
          Marie Laberge<br />
          Michel Chartrand<br />
          Michel Tremblay<br />
          Natasha Kanapé-Fontaine<br />
          René Lévesque<br />
          Robert Bourassa<br />
          Dr Stanley Vollant<br />
          Thérèse Casgrain<br />

          <br /><br />
          <h3 class="medium-title">Sources of photos and works</h3>

          <strong>Phare Îles-de-la-Madeleine</strong> <br />
          Photo credit : L’Île Imagin’air<br /><br />

          <strong>Diver</strong> <br />
          Photo credit : Mickey Charteris<br /><br />

          <strong>Tourisme Québec landscape</strong> <br />
          Photo credit : Steve Deschênes<br /><br />

          <strong>Vauréal waterfall</strong><br />
          Photo credit : Simon Massicotte<br /><br />

          <strong>Le Diamant:</strong><br />
          Photo credit : Stéphane Groleau<br /><br />

          

          <strong>Paysage à Saint-Siméon 1938</strong> <br />
          Photo credit: Marc-Aurèle Fortin<br />
          © Fondation Marc-Aurèle Fortin / SOCAN (2021)<br /><br />

          “Les coupeurs de glace” de Horatio Walker<br /><br />

          “Ice Fishing” de Dominique Normand<br /><br />

          “Thérèse Casgrain” de Adèle Blais<br /><br />

          
          Used with the kind permission of Éditions du Seuil <br />
          Les Fous de Bassan, Anne Hébert, © Éditions du Seuil, 1982, n.e, Points 1998  <br />

          <br /><br />
          <h3 class="medium-title">Sound clips</h3>

          <strong>Negotiation of rights</strong><br />
          Sophie Moreau, LA NÉGO<br /><br />

          <strong>Lance et compte</strong><br />
          Producer : GAEA Films<br />
          Writers : Réjean Tremblay<br />
          Actor: Yvan Ponton<br /><br />

          <strong>Les Boys</strong> <br />
          Producer : ComédiHa! Télévision <br />
          Writers : Louis Saia et Christian Fournier<br />
          Actor: Marc Messier<br /><br />

          <strong>La guerre des tuques</strong> <br />
          Producer : Productions la Fête<br />
          Writers : Roger Cantin et Danyèle Patenaude<br />
          Actor: Mathieu Savard<br /><br />

          <strong>BROUE</strong><br />
          Producer : Théâtre Les Voyagements & Les 3M<br />
          Auteur : Jean-Pierre Plante, Louis Saia, Claude Meunier, Francine Ruel, Michel Côté, Marc Messier et Marcel Gauthier<br />
          Actor: Marc Messier<br /><br />

          <strong>La Petite Vie</strong><br />
          Producer : Avanti groupe<br />
          Writers : Claude Meunier<br />
          Actor : Diane Lavallée<br /><br />

          <strong>Passe Partout</strong><br />
          Producer : JPL Production et ministère de l’Éducation du Québec<br />
          Writers : Bernard Tanguay, Michel Poirier, Ronald Prégent<br />
          Actor : Mireille Lachance, Eve Gagnier, Jocelyne Goyette<br /><br />

          <strong>Watatatow</strong><br />
          Producer : Vivavision<br /><br />

          <strong>Fanfreluche</strong><br />
          Producer : Société Radio-Canada<br />
          Writers : Kim Yaroshevskaya<br />
          Actor : Kim Yaroshevskaya<br /><br />

          <strong>TVA Group Inc.</strong><br />
          Radio-Canada archives<br />
          Télé-Québec<br />
        </div>
      </div>
    </article>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/Footer'

export default {
  head() {
    return {
      title: {
        inner: 'Crédits',
      },
      meta: [
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {}
  },

  mounted() {},

  computed: mapState('app', ['appTitle']),

  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
