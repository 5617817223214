<template>
  <div class="page-wrapper">
    <img src="@/assets/img/bleu.png" alt="Logo Bleu" class="logo" />

    <section class="hero">
      <h2 class="text medium caps mobile-only">Le Québec sous les étoiles</h2>
      <div class="content">
        <h1 class="hero-title">Hôtel du<br />Parlement</h1>
        <p class="hero-title right-align">
          18 <small>AU</small> 22<br />
          <small>ET</small> 24 JUIN
        </p>
      </div>
      <h2 class="text medium caps hero-bottom-title">Le Québec sous les étoiles</h2>
      <img src="@/assets/img/logo-qc-fr.svg" alt="Logo Québec" class="logo-qc" />
      <img src="@/assets/img/arrow-down.svg" alt="arrow" class="scroll" />

      <img src="@/assets/img/hero-dots-1.png" alt="étoiles" class="dots full-h" data-gsap-scroll-speed-1 />
      <img src="@/assets/img/hero-dots-2.png" alt="étoiles" class="dots full-h" data-gsap-scroll-speed-2 />
      <img src="@/assets/img/hero.jpg" alt="Le Québec sous les étoiles" class="background full-h" />
    </section>

    <section class="informations">
      <div class="title-wrap">
        <h2 class="title">INFORMATIONS IMPORTANTES</h2>
      </div>
      <div class="icon-wrap">
        <div class="icon">
          <img src="@/assets/img/icon-map.svg" alt="Icon map" />
          <h3 class="text medium">Lieu</h3>
          <p class="text">Hôtel du Parlement de Québec</p>
          <address class="text small">1045 Rue des Parlementaires, Québec, QC G1A 1A3</address>
          <a
            href="https://www.google.com/maps/dir//Parliament+Building,+1045+Rue+des+Parlementaires,+Quebec+City,+QC+G1A+1A3/@46.8086891,-71.2164144,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x4cb895d9b44dd8d5:0x6bfbd58d64f5475f!2m2!1d-71.2142257!2d46.8086891!3e0"
            target="_blank"
            class="text"
            >Obtenir l’itinéraire</a
          ><br/>
          <a
            href="/static/site-map.pdf"
            target="_blank"
            class="text"
            >Carte de site</a
          >
        </div>
        <div class="icon">
          <img src="@/assets/img/icon-calendar.svg" alt="Icon calendar" />
          <h3 class="text medium">Date</h3>
          <p class="text">
            18 juin <small>au</small> 22 juin <br />
            <small>et</small> 24 juin 2022
          </p>
        </div>
        <div class="icon">
          <img src="@/assets/img/icon-time.svg" alt="Icon Time" />
          <h3 class="text medium">Heure</h3>
          <p class="text">À la tombée de la nuit<br />(à 21h30)</p>
        </div>
        <div class="icon">
          <img src="@/assets/img/icon-smile.svg" alt="Icon Smile" />
          <h3 class="text medium">GRATUIT</h3>
          <p class="text">Ouvert à tous,<br />pour toute la famille</p>
        </div>
      </div>
      <div class="meteo-wrap">
        <img src="@/assets/img/icon-weather.svg" alt="Icon Weather" />
        <p class="text">
          Surveillez la météo, car en cas d’orages violents, le spectacle pourrait être annulé. <br />
          Consultez nos <a href="https://www.facebook.com/events/722871948896240/722871965562905/" target="_blank">médias sociaux</a> pour être au fait des dernières nouvelles
        </p>
      </div>
      <img src="@/assets/img/canot.png" alt="" class="canot" data-gsap-canot />
    </section>

    <section class="description">
      <h2 class="title">NAVIGUEZ À TRAVERS LES MOMENTS FORTS DE L’HISTOIRE DU QUÉBEC</h2>
      <div class="data">
        <div class="left">
          <h3 class="text medium">
            Un tout nouveau spectacle multimédia gratuit <br />
            à l’occasion de la fête nationale
          </h3>
          <div class="video">
            <a href="https://youtu.be/Zjr4RhfAzOo" class="glightbox"><img src="@/assets/img/play.svg" alt="play" class="play" /></a>
            <img src="@/assets/img/video.jpg" alt="Video" class="video-img" />
          </div>
        </div>
        <div class="right">
          <p class="text">
            Un tout nouveau spectacle multimédia grandiose et haut en couleur, BLEU : Le Québec sous les étoiles, sera présenté par le gouvernement du
            Québec en juin prochain sur la façade de l’hôtel du Parlement. Tenu à l’occasion de la fête nationale, soit du 18 juin au 22 juin et le 24
            juin, ce spectacle multimédia envoutant a pour objectif de faire vivre aux spectateurs un périple à travers le territoire, la langue,
            l’histoire et la culture québécoise.
            <br /><br />
            <strong>BLEU : Le Québec sous les étoiles</strong>, c’est une histoire qui oscille entre le fantastique et le fabuleux, entre l’aventure et la
            rencontre, c’est la traversée du Québec en canot d’une grand-mère, interprétée par France Castel, et de son petit-fils. Au cours de cette
            improbable épopée, ils parcourent un monde imaginaire montrant les richesses du Québec d’hier à aujourd’hui, sa culture, son territoire, les
            gens qui l’ont marqué et façonné. <br /><br />
            Un spectacle nourri par l’art, l’histoire, les gens, le territoire et la nature du Québec, qui n’a nulle autre prétention que de faire du bien
            et de faire naître des souvenirs qui seront propres à chacun, selon l’âge et l’intérêt.
          </p>
          <br /><br />
          <a href="https://www.facebook.com/events/722871948896240/722871965562905/" target="_blank">
            <p class="text">Suivez-nous ici</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="18.079" viewBox="0 0 22.079 47.312">
              <g id="Group_129" data-name="Group 129" transform="translate(-151.343 -5053.242)">
                <path id="Path_135" data-name="Path 135" d="M171.845,5081.354h-20.5v-9.462h22.079Z" transform="translate(0 -2.878)" />
                <path
                  id="Facebook"
                  d="M156.989,5062.77v37.785h9.419v-36.273a1.428,1.428,0,0,1,1.577-1.577h6.309v-9.462H167.03C156.745,5053.242,156.989,5061.533,156.989,5062.77Z"
                  transform="translate(-0.871)"
                />
              </g>
            </svg>
          </a>
        </div>
      </div>
      <img src="@/assets/img/canot-dots-1.png" alt="étoiles" class="dots" data-gsap-scroll-speed-1 />
      <img src="@/assets/img/canot-dots-2.png" alt="étoiles" class="dots" data-gsap-scroll-speed-2 />
    </section>

    <section class="testimonials">
      <div class="title-wrap">
        <h2 class="title green">TÉMOIGNAGES</h2>
      </div>
      <div class="main-carousel">
        <div class="carousel-cell">
          <p class="text">
            « BLEU, c’est une fresque numérique sur le Québec. Un fabuleux voyage à travers son histoire, ses paysages, sa culture et ses gens. Un
            spectacle rassembleur qui fait vibrer ! »<br /><br /><span>Nathalie Gélinas, productrice chez TKNL</span>
          </p>
        </div>
        <div class="carousel-cell">
          <p class="text">
            « Encore et toujours la chanson enrobe, ponctue, pimente notre histoire. Dans le spectacle BLEU, elle exprime l’âme du Québec et la nôtre
            aussi. »<br /><br /><span>Monique Giroux, animatrice radio</span>
          </p>
        </div>
        <div class="carousel-cell">
          <p class="text">
            « BLEU c’est un spectacle multimédia sous les étoiles, à la fois touchant et grandiose. Sa trame narrative oscille entre le fantastique et le
            fabuleux, entre l’aventure et la rencontre. »<br /><br /><span> Alexis Laurence, directeur artistique de Le Studio LEX</span>
          </p>
        </div>
        <div class="carousel-cell">
          <p class="text">
            « La musique de BLEU est à la fois grandiose et intimiste. Elle nous fait voyager à travers la beauté de notre héritage culturel, et ce, avec
            la collaboration d'artistes talentueux de chez nous ! »<br /><br /><span> Marc Bell, compositeur et réalisateur, Troublemakers</span>
          </p>
        </div>
      </div>
    </section>

    <section class="partenaires">
      <h2 class="text medium caps">PARTENAIRES</h2>

      <div class="logos-wrap">
        <div class="logo-partenaire big">
          <a href="https://www.quebec.ca/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/logo-qc-fr.svg" alt="Logo Québec" /></a>
        </div>
        <div class="logo-partenaire">
          <a href="http://www.assnat.qc.ca/fr/index.html" target="_blank" rel="noopener noreferrer"
            ><img src="@/assets/img/assemble-fr.png" alt="Logo Assemblée nationnale Québec"
          /></a>
        </div>
        <div class="logo-partenaire">
          <a href="https://www.tknl.com/" target="_blank" rel="noopener noreferrer"
            ><img src="@/assets/img/logo-tknl-fr.svg" alt="Logo TKNL créateurs d'expériences"
          /></a>
        </div>
        <div class="logo-partenaire">
          <a href="https://www.gestev.com/" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/logo-gestev-fr.svg" alt="Logo Gestev" /></a>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'
import Flickity from 'flickity'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Footer from '@/components/Footer'

gsap.registerPlugin(ScrollTrigger)

export default {
  head() {
    return {
      title: {
        inner: 'Le Québec sous les étoiles',
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Le Québec sous les étoiles` },
        {
          name: 'description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `Le Québec sous les étoiles` },
        {
          n: 'twitter:description',
          c: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Le Québec sous les étoiles` },
        { property: 'og:site_name', content: `Le Québec sous les étoiles` },
        { property: 'og:type', content: `Website` },
        {
          property: 'og:description',
          content: `Un tout nouveau spectacle multimédia gratuit à l’occasion de la Fête nationale. Le Parlement de Québec sera la toile d’un tout nouveau spectacle multimédia grandiose, haut en couleur et immersif en juin prochain : BLEU : Le Québec sous les étoiles. L’objectif du spectacle est de générer un sentiment de fierté tout en faisant vivre une expérience multimédia marquante aux spectateurs!`,
        },
        // Og Image
        {
          p: 'og:image',
          c: '/static/og-img.png',
        },
      ],
    }
  },

  components: {
    Footer,
  },

  data() {
    return {
      teamCarousel: null,
    }
  },

  mounted() {
    this.lightboxelement = GLightbox
    GLightbox()

    setTimeout(() => {
      this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
        wrapAround: true,
        imagesLoaded: true,
        prevNextButtons: true,
        pageDots: true,
        autoPlay: 7500,
      })

      this.initGsap()
    }, 1055)
  },

  computed: mapState('app', ['appTitle']),

  methods: {
    initGsap() {
      gsap.utils.toArray('[data-gsap-scroll-speed-1]').forEach((element) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top top',
            end: 'bottom top',
            scrub: 2.475,
          },
          y: '20%',
          ease: 'none',
        })
      })
      gsap.utils.toArray('[data-gsap-scroll-speed-2]').forEach((element) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top top',
            end: 'bottom top',
            scrub: 1.475,
          },
          y: '-40%',
          ease: 'none',
        })
      })
      gsap.utils.toArray('[data-gsap-canot]').forEach((element) => {
        gsap.to(element, {
          scrollTrigger: {
            trigger: element,
            start: 'top bottom',
            end: 'bottom top',
            scrub: 1,
          },
          x: '40vw',
          ease: 'none',
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
