<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'

export default {
  components: { NavBar },
  data() {
    return {
      tempHideSite: false,
    }
  },
  methods: {
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (!document.querySelector('.logo')) {
        return
      }

      if (st >= 105) {
        // document.querySelector('.tiny-logo').classList.add('visible')
        document.querySelector('.logo').classList.add('top')
        document.querySelector('.hero-bottom-title').classList.add('bottom')
        document.querySelector('.logo-qc').classList.add('zoom')
      } else {
        // document.querySelector('.tiny-logo').classList.remove('visible')
        document.querySelector('.logo').classList.remove('top')
        document.querySelector('.hero-bottom-title').classList.remove('bottom')
        document.querySelector('.logo-qc').classList.remove('zoom')
      }
    },
  },
  created() {
    // NavBar Follow
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/typos.scss';
@import '@/theme/landing.scss';
@import '@/theme/glightbox.scss';
@import '@/theme/flickity.scss';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: $color-3;
  font-family: 'Jost', sans-serif;
}

img {
  display: block;
  height: auto;
  width: 100%;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}
.site-hide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  background: #fff;
  z-index: 99999999999999999;
}
</style>
