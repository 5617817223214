<template>
  <header class="navbar" v-if="$route.meta.lang === 'en'">
    <router-link :to="'/en'" v-if="$route && $route.meta.isTerms">
      <img src="@/assets/img/bleu.png" alt="Bleu: A Multimedia show" class="tiny-logo" :class="{ 'force-visible': $route && $route.meta.isTerms }" />
    </router-link>
    <h1 class="text medium caps">Multimedia show</h1>
    <a href="/" class="lang">FR</a>
  </header>
  <header class="navbar" v-else>
    <router-link :to="'/'" v-if="$route && $route.meta.isTerms">
      <img src="@/assets/img/bleu.png" alt="Bleu: Un spectacle multimédia" class="tiny-logo" :class="{ 'force-visible': $route && $route.meta.isTerms }" />
    </router-link>
    <h1 class="text medium caps">Un spectacle multimédia</h1>
    <a href="/en" class="lang">EN</a>
  </header>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 3.5vw 5vw;
  text-align: center;
  z-index: 100;

  @media (max-width: 645px) {
    padding-top: 55px;
  }
}

.lang {
  position: absolute;
  top: 3.6vw;
  right: 5vw;
  color: #fff;
  font-size: 16px;

  transition: color 0.45s ease;

  &:hover {
    color: $color-2;
  }

  @media (max-width: 499px) {
    display: block;
    top: 0;
    right: 0;
    background: #fff;
    color: $color-3;
    padding: 8px 10px;
    border-bottom-left-radius: 3px;
  }
}

.tiny-logo {
  position: fixed;
  top: 10px;
  left: 25px;
  width: 45px;
  z-index: 99;

  opacity: 0;
  transition: opacity 0.65s ease;

  &.force-visible {
    opacity: 1;
  }

  &.visible {
    opacity: 1;
  }
}

.text.medium {
  @media (max-width: 499px) {
    font-size: 15px;
  }
}
</style>
