<template>
  <footer class="footer" v-if="$route.meta.lang === 'en'">
    <div class="left">
      <p class="text medium">Follow us!</p>
      <a href="https://www.facebook.com/events/722871948896240/722871965562905/" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.079" viewBox="0 0 22.079 47.312">
          <g id="Group_129" data-name="Group 129" transform="translate(-151.343 -5053.242)">
            <path id="Path_135" data-name="Path 135" d="M171.845,5081.354h-20.5v-9.462h22.079Z" transform="translate(0 -2.878)" />
            <path
              id="Facebook"
              d="M156.989,5062.77v37.785h9.419v-36.273a1.428,1.428,0,0,1,1.577-1.577h6.309v-9.462H167.03C156.745,5053.242,156.989,5061.533,156.989,5062.77Z"
              transform="translate(-0.871)"
            />
          </g>
        </svg>
      </a>
      <p class="text small">@ 2021 BLEU, Le Québec sous les étoiles</p>
    </div>
    <div class="right">
      <div class="logo-bleu">
        <router-link :to="'/'"><img src="@/assets/img/bleu-small.png" alt="Logo bleu" /></router-link>
      </div>
      <nav>
        <a href="/en/credits" class="text">Credits</a>
        <a href="/en/rules" class="text">Rules</a>
        <a href="/en/policy" class="text">Privacy Policy</a>
        <a href="/en/press" class="text">Press</a>
        <a href="/en/faq" class="text">FAQ</a>
      </nav>
      <p class="text contact">
        Contact us<br />
        <span>
          GESTEV - Bureau principal <br />
          250-G, boul. Wilfrid-Hamel <br />
          Québec (QC), G1L 5A7 <br /><br />
          <a href="tel:4189771122">418-977-1122</a> <br />
          <a href="mailto:info@spectaclebleu.com">info@spectaclebleu.com</a>
        </span>
      </p>
    </div>
  </footer>
  <footer class="footer" v-else>
    <div class="left">
      <p class="text medium">Suivez-nous!</p>
      <a href="https://www.facebook.com/events/722871948896240/722871965562905/" target="_blank">
        <svg xmlns="http://www.w3.org/2000/svg" width="14.079" viewBox="0 0 22.079 47.312">
          <g id="Group_129" data-name="Group 129" transform="translate(-151.343 -5053.242)">
            <path id="Path_135" data-name="Path 135" d="M171.845,5081.354h-20.5v-9.462h22.079Z" transform="translate(0 -2.878)" />
            <path
              id="Facebook"
              d="M156.989,5062.77v37.785h9.419v-36.273a1.428,1.428,0,0,1,1.577-1.577h6.309v-9.462H167.03C156.745,5053.242,156.989,5061.533,156.989,5062.77Z"
              transform="translate(-0.871)"
            />
          </g>
        </svg>
      </a>
      <p class="text small">@ 2021 BLEU, Le Québec sous les étoiles</p>
    </div>
    <div class="right">
      <div class="logo-bleu">
        <router-link :to="'/'"><img src="@/assets/img/bleu-small.png" alt="Logo bleu" /></router-link>
      </div>
      <nav>
        <a href="/credits" class="text">Crédits</a>
        <a href="/reglements" class="text">Règlements</a>
        <a href="/politiques" class="text">Politique vie privée</a>
        <a href="/medias" class="text">Médias</a>
        <a href="/faq" class="text">FAQ</a>
      </nav>
      <p class="text contact">
        Nous joindre<br />
        <span>
          GESTEV - Bureau principal <br />
          250-G, boul. Wilfrid-Hamel <br />
          Québec (QC), G1L 5A7 <br /><br />
          <a href="tel:4189771122">418-977-1122</a> <br />
          <a href="mailto:info@spectaclebleu.com">info@spectaclebleu.com</a>
        </span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
